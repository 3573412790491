 .chat {
  height: 100%;
  padding-bottom: 20px;
  margin-top: 20px;
}

.chat ul {
  margin: 0;
  padding: 0;
} 
