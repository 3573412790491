.form {
  position: relative;
  width: 100%;
}

.input-wrapper {
  display: inline-block;
  width: 80%;
}

.submit {
  padding: 0;
  display: inline-block;
  width: 20%;
  height: 2.1875rem;
  vertical-align: top;
}

/* MessageInput Styles */

.input {
  width: 100%;
  height: 2.1875rem;
  background: "white";
}

.input__input {
  width: 100%;
  padding: 0.4em 0.8em;
  font-size: 0.94rem;
  height: 2.1875rem;
  color: #555555;
  background-image: none;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
