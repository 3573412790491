.loading-circle {
  /* position: absolute; */
  /* left: 50%;
  top: 50%; */
  /* z-index: 1; */
  width: 80px;
  height: 80px;
  /* margin: -60px 0 0 -60px; */
  margin: 20px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #e52d5b;
  border-radius: 50% !important;
  animation: loading-circle-spin 3s linear infinite;
}

@keyframes loading-circle-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
