.message {
  list-style: none;
  display: flex;
}

.message:not(:first-child) {
  margin-top: 0.6rem;
}

.user-avatar {
  flex-shrink: 0;
  width: 25px;
  margin-top: 2px;
  margin-right: 10px;
}

.message-content {
  flex: 1;
  position: relative;
  min-width: 0;
}

.username {
  font-size: 0.875rem;
}

.role-developer .username {
  color: #fbb829;
}

.role-moderators .username {
  color: #9c92e7;
}

.role-supporter .username {
  color: #51aeff;
}

.role-system .username {
  color: #999;
}

.role-user .username {
  color: #337ab7;
}

.time {
  color: rgb(129, 129, 129);
  font-size: 0.7rem;
  margin-left: 0.4rem;
}

.menu {
  float: right;
  position: absolute;
  top: 0;
  right: 0;
}

.menu-dropdown {
  min-width: unset;
}

.menu button {
  visibility: hidden;
  color: rgb(129, 129, 129);
  background: none;
  border: none;
  padding: 0;
  outline: none;
}

.message:hover .menu button {
  visibility: visible;
}

.menu button:hover {
  color: #337ab7;
}

.message-body {
  margin-top: 0.19rem;
  font-size: 0.8rem;
  color: #eee;
}

.edited .message-body::after {
  content: "(edited)";
  color: rgb(129, 129, 129);
  font-size: 0.62rem;
  margin-left: 0.44rem;
}

.message-body a {
  color: #999;
}

.edit-message {
  color: #555;
  position: absolute;
  top: 0px;
  right: 0px;
}

.dropdown-item {
  cursor: pointer;
}
