.input {
  width: 100%;
  height: 2.1rem;
  background: white;
}

.input__control {
  width: 100%;
  height: 2.1rem;
}

.input__highlighter {
  height: 2.1rem;
  padding: 0.4em 0.9em;
  border: 1px;
  line-height: 1.56rem;
  white-space: normal !important;
}

.input__input {
  width: 100%;
  padding: 0.4em 0.8em;
  font-size: 0.94rem;
  height: 2.1rem;
  color: #555555;
  background-image: none;
  /* border: 1px solid #ccc; */
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.input__suggestions {
  color: black;
  margin-top: 0 !important;
  top: 100% !important;
}

.input__suggestions__list {
}

.input__suggestions__item {
  padding: 8px 16px;
}

.input__suggestions__item--focused {
  background-color: lightblue;
}

.input__mention {
  background-color: lightsteelblue;
}
